.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Universal Styles */

html {
  font-size: 18px;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */



#color{
  background-color:#eef5db;
}




/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
}

h1 {
  padding: .66rem 0;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: black;
}

.row{
  display: grid !important;
  width:100vw;
  flex-direction: row;
  grid-template-rows: 1fr;
}

/* start of summary change */

.aboutMe{
  display: grid;
  min-height:20vh;
  width:100vw;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  background-color: 	#B8B8B8;

}

.about-content{
 display:grid;
  width:60%;

}
.summary{
  display: grid;
  grid-template-columns: 1fr;
  min-height:10vh;
  width:100vw;
}

.subtitle{
  display: grid;
  min-height: 3vh;
  min-width: 100vw;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  justify-content:space-around;
}

.subtitle h2{
  grid-column-start: 2;
  color:#555;

}


.subtitle h4{
  grid-column-start: 0;
  font-size: 1em;
  justify-self:right;
}

.sub-colors{
  background-color: 	#B8B8B8;
}

.summary-content{
    display: grid;
    grid-template-rows: 1fr;
    width:100vw;
    bottom:10%;
    justify-items: center;
    padding-bottom:1em;
    padding-right: 15px;
    padding-left: 15px;

  }



.skill-box{
  display:grid;
  width: 50vw;
  grid-template-columns: 2fr 10fr;
}

.skill-icon {
  display: grid;
  justify-content:center ;
}


.skill-icon img{

  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left:20px;
  margin-right: 5px;
  width: 70px;
 
}

.skill-description{
  display: grid;
  width  :80vw;

}

.skill-description h3{
  font-size: 1em;
}
/* media query of summary change */
@media only screen and (min-width: 698px)  {
  .summary-content p{
    width: 70%;
    color:#555;
    font-size: 1.8rem;
  }
  .row-box  p{
    color:#555;
    font-size: 1.8rem;
  
  }
  
  .aboutMe p {
    color:#555;
    font-size: 1.8rem;
  }

  .skill-description{
    color:#555;
    font-size: 1.8rem;
  }

  .row{
    grid-template-columns: 1fr 1fr;
    column-gap: 0;
    margin-left: 40px !important;
  }

  .row-box{
    min-height:10vh;
    display: flex;
    flex-direction: column;
  }

  .skill-box{
    width: 30%;
    justify-items: center;

  }
  .skill-description{
    width: 30vw;
  }

  .skill-icon img{
    padding-right: 20px !important;
  }


  .aboutMe{
    min-height:25vh;
    font-size:20px;

  }

  .sub-colors{
    display: grid;
    min-height: 3vh;
    min-width:100vw;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }

  .sub-colors h4{
      grid-column-start: 1;
      background-color: rebeccapurple !important;
  }
}

/* end of summary change */

.cardHolder{
  display: grid;
  height:50vh;
  width:100vw;
  grid-template-columns: 1fr;
  background-color: #858585;
}

.displaySlider{
  height:40vh;
  width:80vw;
  align-self: center;
  justify-self: center;
  background-color: aquamarine;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows:  1fr 1fr 1frz;
  justify-content: space-around;
  align-items:center ;
  -webkit-overflow-scrolling: auto;
  overflow-x: scroll;
}

.displayRow{
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.splash {
  width:100%;
  background-size:cover;
  background-position:50% 50%;
  background-repeat: no-repeat;
  transition: all 0.6s ease;
  flex:none;
}

.card{
  display:grid;
  min-width: 10vw !important;
  height:100%;
  width:100%;
  grid-template-rows: 1fr ;
  grid-auto-rows: 1fr;
  overflow: hidden; 
  
  
  flex:1;
  -webkit-flex:1;
  text-align:center;
}

.non-card{
  min-height:100%;
  width:100%;
  background-color: #858585;
}









.content
{
  opacity: 0.5;
  background: rgba( 0, 0, 0, 0.9 );
  bottom: -100%;
  color: #fff;
  height: 30%;
  left: 0%;
  text-align: center;
  position: absolute;
  transition: bottom 0.5s ease;
  width: 100%;
  visibility: hidden;
  transition: all 800ms ease-out;
  overflow: hidden;

}


.card:hover .content
{
  bottom: 0%;
  visibility: visible;
  transition: all 1s ease-out;

}


.landing{
  width:100%;
  display:grid;

  grid-template-columns: 1fr;

}



.text-container{
  background-color:  hsl(147, 64%, 61%);
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;

}

.text-box{
  width:80%;
}

.hero-content{
    color: #241c15;
    font-family: Cooper,Georgia,Times,Times New Roman,serif;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: -.03125rem;
    line-height: 1;
    width:40%;
    background-color: white;
    opacity: 0.3;
    justify-self: center;
    align-self: end;

  }




.image-container { 
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.image-container-recipe {
    background-image: linear-gradient(to right, #212529 , #5cdb95); 
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}



.cover-photo{
  display: grid;
  align-items: center;
  filter: grayscale(100%);

  background-image: url(https://static.vecteezy.com/system/resources/previews/021/957/688/non_2x/of-purple-flowers-during-the-day-free-photo.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width:80%;
  height:60%;
}


.cover-photo-two{
   
  background-image: url(https://marketplace.canva.com/MADGx-V23ss/4/thumbnail_large/canva-brass-round-7-stack-coins-MADGx-V23ss.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width:80%;
  height:60%;
}

#sticky-footer p{
  margin-top:1rem;
  text-align:center;
  vertical-align: middle;
}
/*
media query for when the screen is large
*/


@media only screen and (min-width: 1024px) {
  .subtitle{
    grid-template-columns: repeat(5, 1fr);
  }
  .subtitle h2{
    grid-column-start: 3;
    color:#555;
  
  }

  .cover-image-one{
    background-image: url(https://eliimagebucket.s3.amazonaws.com/cloud2.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    width:100%;
    height:100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(50%);
    overflow: hidden;
  
  }

  .cover-image-two{
    background-image: url(https://eliimagebucket.s3.amazonaws.com/public-rds.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    width:100%;
    height:100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(50%);
    overflow: hidden;
  }

  .cover-image-three{
    background-image: url(https://eliimagebucket.s3.amazonaws.com/ec2-api.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    width:100%;
    height:100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(50%);
    overflow: hidden;
  }
  .company_view {
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
  }
  .landing{
    height:100vh;
  }

  .hero-content{
    width:20%;
    align-self: end;
  } 

}

@media only screen and (max-width: 1024px) {
   .company_view {
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
  }
 
  .landing{
    display: grid;
    grid-template-columns: 1fr;
  }
  

 
  .cover-photo{
    height:100%;
    width:90%;
  }
  
  .cover-photo-two{
    height:80%;
    width:90%;
  }
  
  .image-container {
    background-color:white;
    height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
}

.card .content
{
  bottom: 0%;
  visibility: visible;
}

.content-p {
  font-size: 0.6em;
}

.cover-image-one{
  background-image: url(https://eliimagebucket.s3.amazonaws.com/cloudfront-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width:100%;
  height:100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(50%);
  overflow: hidden;

}

.cover-image-two{
  background-image: url(https://eliimagebucket.s3.amazonaws.com/public-rds-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width:100%;
  height:100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(50%);
  overflow: hidden;
}
.cover-image-three{
  background-image: url(https://eliimagebucket.s3.amazonaws.com/ec2-api-small.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  width:100%;
  height:100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(50%);
  overflow: hidden;
}

#sticky-footer{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-content:end;
}

#sticky-footer p{
  margin-top:1rem;
  text-align:center;
  vertical-align: middle;
}

.empty{
  display: grid;
  height:4vh;
}
}
